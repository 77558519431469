import React from "react";
import { GiCricketBat } from "react-icons/gi";

const Marker = () => {
  return (
    <div>
      <h1>
        <GiCricketBat />
      </h1>
    </div>
  );
};

export default Marker;
