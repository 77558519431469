import { supabase } from "../supabaseClient";

const subscribeToMessages = (conversationId, setMessages) => {
  const subscription = supabase
    .channel("CONVERSATIONS")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "messages",
        filter: `conversation_id=eq.${conversationId}`,
      },
      async (payload) => {
        if (payload.new.conversation_id === conversationId) {
          const { data: messages, error } = await supabase
            .from("messages")
            .select("*")
            .eq("conversation_id", conversationId);
          if (error) {
            console.error("Error fetching messages:", error);
          } else {
            setMessages(messages);
          }
        } else {
          console.log("No new messages");
        }
      }
    )
    .subscribe();

  return () => {
    supabase.removeChannel(subscription);
  };
};

export default subscribeToMessages;
