// Components/PrivateRoute.js
import React from "react";
import { supabase } from "./supabaseClient";

const PrivateRoute = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
      setLoading(false);
    };
    getUser();
    supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
    });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : (window.location.href = "/login");
};

export default PrivateRoute;
