import { createSlice } from "@reduxjs/toolkit";

const userProfileSlice = createSlice({
  name: "profile",
  initialState: {
    email: "",
    clubName: "",
    clubPhoto: "",
    clubAddress: "",
    clubPhone: "",
  },
  reducers: {
    profile: {
      reducer(state, action) {
        state.email = action.payload.email;
        state.clubName = action.payload.clubName;
        state.clubPhoto = action.payload.clubPhoto;
        state.clubAddress = action.payload.clubAddress;
        state.clubPhone = action.payload.clubPhone;
      },
      prepare(email, clubName, clubPhoto, clubAddress, clubPhone) {
        return {
          payload: { email, clubName, clubPhoto, clubAddress, clubPhone },
        };
      },
    },
  },
});

export const { profile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
