import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Make sure to import useLocation
import { supabase } from "../Components/supabaseClient";
import GoogleMapReact from "google-map-react";
import Marker from "../Components/Hooks/Marker";

const ClubLocation = () => {
  const [clubLocation, setClubLocation] = useState({
    lat: 51.50101009999999,
    lng: -0.1415627,
  });
  const [postalCode, setPostalCode] = useState("");
  const [club, setClub] = useState("");
  const api = process.env.REACT_APP_GOOGLE_MAPS;

  const location = useLocation();

  const convertPostCode = async (postalCode) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${api}`
    );
    const data = await response.json();
    const location = data.results[0].geometry.location;
    return location;
  };

  useEffect(() => {
    const { clubName } = location.state || {};
    setClub(clubName || "Default Club   Name");
    console.log(clubName);
  }, [location.state]);

  useEffect(() => {
    if (club) {
      const getLocation = async () => {
        const { data, error } = await supabase
          .from("userProfile")
          .select("*")
          .eq("club_name", club);
        if (data && data.length > 0) {
          setPostalCode(data[0].club_location);
        }
      };
      getLocation();
    }
  }, [club]);

  useEffect(() => {
    if (postalCode) {
      convertPostCode(postalCode).then((location) => {
        setClubLocation(location);
      });
    }
  }, [postalCode]);

  return (
    <div>
      <h1>Club Location</h1>
      <div className="map-container">
        <GoogleMapReact
          bootstrapURLKeys={{ key: api }}
          defaultCenter={clubLocation}
          center={clubLocation}
          defaultZoom={17}
        >
          <Marker lat={clubLocation.lat} lng={clubLocation.lng} />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default ClubLocation;
