import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Colors } from "../Components/Theme/Colors";

const AboutUs = () => {
  const handleNavigation = () => {
    window.location.href = "/contactUs";
  };

  return (
    <Container style={styles.container}>
      <Typography variant="h3" style={styles.header}>
        About FixtureFix
      </Typography>
      <Typography variant="body1" style={styles.bodyText}>
        I am passionate about creating a platform that allows teams to find
        fixtures with ease. I have been playing cricket for over 10 years and
        have always struggled to find fixtures for my team.
        <br />
        I have created FixtureFix to solve this problem.
        <br />I hope you enjoy using the app as much as I have enjoyed creating
        it.
      </Typography>
      <Typography variant="body1" style={styles.bodyText}>
        If you have any feedback or suggestions, please contact me on the
        contact page here.
      </Typography>
      <div style={styles.buttonBox}>
        <Button
          variant="contained"
          style={styles.button}
          onClick={handleNavigation}
        >
          Contact Us
        </Button>
      </div>
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100vh",
    padding: "20px",
    backgroundColor: Colors.white,
  },
  header: {
    padding: "20px",
    textAlign: "center",
    borderRadius: "25px",
    height: "auto",
    color: Colors.white,
    backgroundColor: Colors.pink,
  },
  bodyText: {
    padding: "20px",
    textAlign: "center",
    color: Colors.black40,
    backgroundColor: Colors.white,
    margin: "10px 0",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  button: {
    backgroundColor: Colors.pink,
    color: Colors.white,
    width: "200px",
  },
};

export default AboutUs;
