import React, { useCallback, useState, useEffect } from "react";
import { postcodeValidator } from "postcode-validator";
import { supabase } from "../Components/supabaseClient";
import { useLocation } from "react-router-dom";
import generateSecureRandomCode from "../Components/Hooks/generateRandomCode";
import "../Components/Styles/LoginAfterSigningUp.css";

const LoginAfterSigningUp = () => {
  const location = useLocation();
  const [venueLocation, setVenueLocation] = useState("");
  const [clubName, setClubName] = useState("");
  const [randomCode, setRandomCode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState("");
  const [id, setId] = useState("");

  const signUserIn = useCallback(async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: location.state.email,
      password: location.state.password,
    });
    error ? alert("Error logging in: " + error.message) : null;
  }, [location.state.email, location.state.password]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) throw error;
        setId(data.user.id);
        await supabase.auth.refreshSession();
        signUserIn();
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, [signUserIn]);

  const postcodeverify = async (e) => {
    e.preventDefault();
    const isValid = postcodeValidator(venueLocation, "GB");
    if (isValid) {
      populateUserProfile();
    } else {
      alert("Please enter a valid postcode: In this format SW11 1AA");
    }
  };

  const populateUserProfile = async () => {
    const length = 4;
    const fcmToken = generateSecureRandomCode(length);
    try {
      const { error } = await supabase
        .from("userProfile")
        .insert([
          {
            club_location: venueLocation,
            club_email: email,
            club_name: clubName,
            club_photo: logo,
            user_profile_icon_code: randomCode,
            contact_number: contact,
            club_uuid: id,
            fcm_token: fcmToken,
          },
        ])
        .select();
      if (error) {
        console.error("Error adding user profile:", error);
      } else {
        window.location.href = "/dashboard";
      }
    } catch (error) {
      alert("Error", error.message);
    }
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const length = 4;
        const code = generateSecureRandomCode(length);
        const { data, error } = await supabase.storage
          .from("user_profile_avatars")
          .upload(code, file);
        console.log("Data:", data);
        if (error) {
          console.error("Error uploading image:", error);
          return;
        }

        const { publicURL, error: urlError } = supabase.storage
          .from("user-profiles")
          .getPublicUrl(`public/${file.name}`);

        if (urlError) {
          console.error("Error getting public URL:", urlError);
          return;
        }

        console.log("Public URL of uploaded image:", publicURL);
        setRandomCode(code);
        setImageUrl(publicURL);
        window.location("/dashboard");
      } catch (err) {
        console.error("Unexpected error during image upload:", err);
      }
    }
  };

  return (
    <div className="login-after-signing-up">
      <h1>User Profile</h1>
      <div className="user-profile">
        <form className="user-profile-form" onSubmit={postcodeverify}>
          <input
            className="input"
            type="text"
            placeholder="Club Name"
            value={clubName}
            onChange={(e) => setClubName(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Location (e.g., SW18 4RD)"
            value={venueLocation}
            onChange={(e) => setVenueLocation(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Contact"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
          <textarea
            className="input"
            placeholder="Club Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            className="input"
            type="file"
            accept="image/*"
            onChange={uploadImage}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <button className="button" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginAfterSigningUp;
