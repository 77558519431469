import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
} from "@mui/material";
import { supabase } from "../Components/supabaseClient";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import { profile } from "../Components/Redux/userProfile";
import "../App.css";
import { useNavigate, useLocation } from "react-router-dom";

const Dashboard = () => {
  const [userId, setUserId] = useState("");
  const [clubName, setClubName] = useState("");
  // eslint-disable-next-line
  const [clubPhoto, setClubPhoto] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fixtures, setFixtures] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const location = useLocation();
  useEffect(() => {
    const getUser = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) throw error;
        setUserId(data.user.id);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!userId) return;
      try {
        const { data, error } = await supabase
          .from("userProfile")
          .select("*")
          .eq("club_uuid", userId);
        if (error) throw error;
        if (data.length > 0) {
          const userProfile = data[0];
          setClubName(userProfile.club_name);
          const clubPhotoBase64 = JSON.parse(userProfile.club_photo).assets[0]
            .base64;
          setClubPhoto(clubPhotoBase64);
          if (userProfile) {
            try {
              const clubPhotoBase64 = JSON.parse(userProfile.club_photo)
                .assets[0].base64;
              dispatch(
                profile(
                  userProfile.email,
                  userProfile.club_name,
                  clubPhotoBase64,
                  userProfile.club_address,
                  userProfile.club_phone
                )
              );
            } catch (error) {
              console.error("Error dispatching user info:", error);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserInfo();
  }, [userId, dispatch]);

  const getAllFixtures = useCallback(async (date) => {
    setSelectedDate(date);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    try {
      const { data, error } = await supabase
        .from("fixture")
        .select("*")
        .eq("fixture_date", formattedDate);
      if (error) throw error;
      setFixtures(data);
    } catch (error) {
      console.error("Error fetching fixtures:", error);
    }
  }, []);

  useEffect(() => {
    getAllFixtures(selectedDate);
  }, [selectedDate, getAllFixtures]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const sendClubName = (clubName) => {
    navigate("/clubprofile", {
      state: {
        clubName: clubName,
      },
    });
  };

  const addFixture = () => {
    window.location.href = "/addfixture";
  };

  return (
    <Container maxWidth="md">
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        onClick={() => sendClubName(clubName)}
      >
        <strong>{clubName}</strong>
      </Typography>
      <Box display="flex" justifyContent="center" marginBottom={3}>
        <Calendar
          className="calendar"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </Box>
      <List>
        {fixtures.map((fixture, index) => (
          <ListItem key={index}>
            <Card variant="outlined" style={{ width: "100%" }}>
              <CardContent>
                <Typography variant="h6">
                  Team Name: {fixture.fixture_team_name}
                </Typography>
                <Typography>Fixture Date: {fixture.fixture_date}</Typography>
                <Typography
                  className="touchable-team-name"
                  onClick={() => sendClubName(fixture.club_name)}
                >
                  Club Name: <strong>{fixture.club_name}</strong>
                </Typography>
                <Typography>
                  Description:
                  {fixture.fixture_description
                    ? fixture.fixture_description
                    : "No description"}
                </Typography>
                <Typography>
                  Fixture Location: {fixture.fixture_venue}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.location.href = `/chatscreen`;
                  }}
                >
                  Chat with us
                </Button>
              </CardContent>
            </Card>
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="center" marginTop={3}>
        <Button variant="contained" color="primary" onClick={addFixture}>
          Add Fixture
        </Button>
      </Box>
    </Container>
  );
};

export default Dashboard;
