import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";

const SubscriptionScreen = () => {
  return (
    <Container style={styles.container}>
      <Box style={styles.headerBox}>
        <Typography variant="h3" align="center" gutterBottom>
          Subscription Screen
        </Typography>
      </Box>
      <Box style={styles.contentBox}>
        <Typography variant="h5" align="center" gutterBottom>
          Servers cost money
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          I have to pay for servers to keep this app running. If you like this
          app, please consider subscribing to help me pay for the servers.
          <br />
          I do not agree with using ads to pay for a service. I believe that if
          you like a service, you should pay for it. This is why I am asking for
          a subscription.
          <br />
          I will never sell your data to make money. I believe that your data is
          yours and should not be sold.
          <br />
          The benefits of the app is that there is a community of cricket clubs{" "}
          <br />
          that you can chat with and share your fixtures with. Making finding
          games seamless
          <br />
          <br />
          If you have any questions please contact me,{" "}
          <a href="/contactus">contact me</a>.
        </Typography>
        <Typography variant="h6" align="center" paragraph>
          Subscription Benefits:
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          1. Access to all the features of the app
        </Typography>
        <Typography variant="h6" align="center" paragraph>
          Subscription Cost: $100/year
        </Typography>
        <Box style={styles.buttonBox}>
          <Button variant="contained" color="primary">
            Subscribe
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  headerBox: {
    marginBottom: "20px",
  },
  contentBox: {
    maxWidth: "600px",
    textAlign: "center",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
};

export default SubscriptionScreen;
