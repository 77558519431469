import React from "react";
import { supabase } from "../Components/supabaseClient";
import { Button, Container, Typography } from "@mui/material";

const Logout = () => {
  const logUserOut = async () => {
    await supabase.auth.signOut();
    window.location.href = "/";
  };

  return (
    <Container style={styles.container}>
      <Typography variant="h3" align="center" gutterBottom>
        Logout
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={logUserOut}
        style={styles.button}
      >
        Logout
      </Button>
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  button: {
    marginTop: "20px",
    width: "200px",
  },
};

export default Logout;
