import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logo from "../Components/Images/logo.png";
import { Colors } from "../Components/Theme/Colors";

const HeaderComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const tAndC = () => {
    window.location.href = "/tandc";
  };
  const contactUs = () => {
    window.location.href = "/contactus";
  };
  const homeNav = () => {
    window.location.href = "/";
  };
  const aboutUs = () => {
    window.location.href = "/aboutUs";
  };
  const login = () => {
    window.location.href = "/login";
  };
  const logout = () => {
    window.location.href = "/logout";
  };
  const chatscreen = () => {
    window.location.href = "/chatscreen";
  };
  const allClubs = () => {
    window.location.href = "/clubList";
  };
  const subscriptionScreen = () => {
    window.location.href = "/subscriptionscreen";
  };
  const deleteprofile = () => {
    window.location.href = "/deleteprofile";
  };
  return (
    <AppBar position="static" sx={{ backgroundColor: Colors.black }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={login}>Login</MenuItem>
          <MenuItem onClick={handleMenuClose}>FAQ</MenuItem>
          <MenuItem onClick={handleMenuClose}>Privacy Policy </MenuItem>
          <MenuItem onClick={contactUs}>Contact Us</MenuItem>
          <MenuItem onClick={tAndC}>Terms And Conditions</MenuItem>
          <MenuItem onClick={aboutUs}>About Us</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
          <MenuItem onClick={chatscreen}>Chat</MenuItem>
          <MenuItem onClick={allClubs}>All Clubs</MenuItem>
          <MenuItem onClick={subscriptionScreen}>Subscription</MenuItem>
          <MenuItem onClick={deleteprofile}>Delete Profile</MenuItem>
        </Menu>
        <Typography
          variant="h3"
          sx={{ flexGrow: 1, textAlign: "center" }}
          onClick={() => (window.location.href = "./dashboard")}
        >
          FixtureFix
        </Typography>
        <img src={logo} alt="logo" height={75} onClick={homeNav} />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderComponent;
