import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetchClubs from "../Components/Hooks/useFetchClubs";
import { useCheckExistingConversation } from "../Components/Hooks/CheckExistingConversation";
import useSendMessage from "../Components/Hooks/useSendMessage";
import { supabase } from "../Components/supabaseClient";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

const ChatScreen = () => {
  const [newMessages, setNewMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [clubImages, setClubImages] = useState({});

  const { clubName } = useParams();
  const clubs = useFetchClubs();
  const {
    clickedClubName,
    signedInUserClubName,
    existingConversationId,
    checkExistingConversation,
  } = useCheckExistingConversation(clubName);
  const { message, setMessage, sendMessage } = useSendMessage(
    existingConversationId,
    signedInUserClubName
  );

  const onMessageClick = () => {
    sendMessage();
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        message_content: message,
        sender_id: signedInUserClubName,
        conversation_id: existingConversationId,
      },
    ]);
    setMessage("");
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from("messages")
        .select("*")
        .eq("conversation_id", existingConversationId);
      if (error) {
        console.error("Error fetching messages:", error);
      } else {
        setMessages(data);
      }
    };

    if (existingConversationId) {
      fetchMessages();
      subscribeToConversations();
    }
  }, [existingConversationId]);

  const subscribeToConversations = () => {
    supabase
      .channel("CONVERSATIONS")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "messages" },
        (payload) => {
          setNewMessages((prevNewMessages) => [
            ...prevNewMessages,
            payload.new,
          ]);
        }
      )
      .subscribe();
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const { data, error } = await supabase.from("userProfile").select("*");
      if (error) {
        console.error("Error fetching user profile:", error);
      } else {
        const images = {};
        for (const profile of data) {
          const imageUrl = await fetchImage(profile.user_profile_icon_code);
          images[profile.club_name] = imageUrl;
        }
        setClubImages(images);
      }
    };

    const fetchImage = async (randomCode) => {
      const { data, error } = supabase.storage
        .from("user_profile_avatars")
        .getPublicUrl(randomCode);
      if (error) {
        console.error("Error fetching image URL:", error);
      } else {
        return data.publicUrl;
      }
    };

    fetchUserProfile();
  }, [clubName]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: "2rem" }}>
      <Box display="flex">
        <Box flex={1} paddingRight="1rem">
          <Typography variant="h5">Clubs</Typography>
          <List>
            {clubs.map((club) => (
              <ListItem
                onClick={() => checkExistingConversation(club.club_name)}
                key={club.club_uuid}
              >
                <ListItemAvatar>
                  <img
                    src={clubImages[club.club_name]}
                    alt="Club Logo"
                    width="50"
                    height="50"
                    style={{ borderRadius: "50%" }}
                  />
                </ListItemAvatar>
                <ListItemText primary={club.club_name} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box flex={3} paddingLeft="1rem" position="relative">
          <Typography variant="h5">{clickedClubName}</Typography>
          <Box marginBottom="4rem" paddingBottom="4rem">
            {[...messages, ...newMessages].map((msg, index) => (
              <Box
                key={index}
                textAlign={
                  msg.sender_id === signedInUserClubName ? "left" : "right"
                }
                marginBottom="10px"
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    backgroundColor:
                      msg.sender_id === signedInUserClubName
                        ? "#e0f7fa"
                        : "#fce4ec",
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  {msg.sender_id === signedInUserClubName
                    ? "You: "
                    : `${msg.sender_id}: `}
                  {msg.message_content}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            position="absolute"
            bottom="1rem"
            width="calc(100% - 2rem)"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              variant="outlined"
              placeholder="Type a message"
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{ marginRight: "1rem" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={onMessageClick}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ChatScreen;
