import React, { useState } from "react";
import { supabase } from "../Components/supabaseClient";
import { postcodeValidator } from "postcode-validator";
import generateSecureRandomCode from "../Components/Hooks/generateRandomCode";

const EditProfile = () => {
  const [venueLocation, setVenueLocation] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contact, setContact] = useState("");
  const [randomCode, setRandomCode] = useState("");
  const [description, setDescription] = useState("");
  const [clubUuid, setClubUuid] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const length = 4;
        const code = generateSecureRandomCode(length);
        const { data, error } = await supabase.storage
          .from("user_profile_avatars")
          .upload(code, file);
        console.log("Data:", data);
        if (error) {
          console.error("Error uploading image:", error);
          return;
        }

        const { publicURL, error: urlError } = supabase.storage
          .from("user-profiles")
          .getPublicUrl(`public/${file.name}`);

        if (urlError) {
          console.error("Error getting public URL:", urlError);
          return;
        }
        setRandomCode(code);
        setImageUrl(publicURL);
        window.location("/dashboard");
      } catch (err) {
        console.error("Unexpected error during image upload:", err);
      }
    }
  };

  const populateUserProfile = async () => {
    setClubUuid((await supabase.auth.getUser()).data.user.id);
    try {
      const { data, error } = await supabase
        .from("userProfile")
        .update({
          club_location: venueLocation,
          club_email: email,
          contact_number: phoneNumber,
          user_profile_icon_code: randomCode,
          club_description: description,
        })
        .eq("club_uuid", clubUuid);
      if (error) {
        console.error("Error updating data in the database:", error);
      }
    } catch (err) {
      console.error("Unexpected error during profile update:", err);
    }
  };

  const postcodeverify = async (e) => {
    e.preventDefault();
    const isValid = postcodeValidator(venueLocation, "GB");
    if (isValid) {
      await populateUserProfile();
    } else {
      alert("Please enter a valid postcode: In this format SW11 1AA");
    }
  };

  return (
    <div className="login-after-signing-up">
      <h1>User Profile</h1>
      <div className="user-profile">
        <form className="user-profile-form" onSubmit={postcodeverify}>
          <input
            className="input"
            type="text"
            placeholder="Club Location (e.g., SW18 4RD)"
            value={venueLocation}
            onChange={(e) => setVenueLocation(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Contact"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
          <textarea
            className="input"
            placeholder="Club Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            className="input"
            type="file"
            accept="image/*"
            onChange={(e) => uploadImage(e)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <button className="button" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
