import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";

const ContactUs = ({ supabase }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = async () => {
    try {
      const { data, error } = await supabase
        .from("webContactUs")
        .insert([{ name: name, email: email, message: message }])
        .select("*");
      if (error) {
        console.log("error", error);
      } else {
        console.log("data", data);
      }
    } catch (error) {
      console.log("error, error in sending message", error);
    }
  };
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name === "" || !emailRegex.test(email) || message === "") {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [name, email, message]);

  return (
    <Box
      sx={{
        display: "flex",
        flexdirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <Box sx={{ flexGrow: 1, alignSelf: "center" }}>
        <Typography variant="h4" align="center">
          Contact Us
        </Typography>
        <Typography variant="body1" align="center">
          We would love to hear from you
        </Typography>
      </Box>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexdirection: "column",
          alignItems: "center",
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="message"
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableButton}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUs;
