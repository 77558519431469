import { useState } from "react";
import { supabase } from "../supabaseClient";
import generateSecureRandomCode from "./generateRandomCode";

export const useCheckExistingConversation = () => {
  const [messages, setMessages] = useState([]);
  const [clickedClubName, setClickedClubName] = useState("");
  const [signedInUserClubName, setSignedInUserClubName] = useState("");
  const [existingConversationId, setExistingConversationId] = useState("");

  const checkExistingConversation = async (clubName) => {
    setClickedClubName(clubName);
    try {
      const { data: authData, error: authError } =
        await supabase.auth.getUser();
      if (authError) throw authError;
      const signedInUserId = authData.user.id;

      const { data: userProfileData, error: userProfileError } = await supabase
        .from("userProfile")
        .select("club_name")
        .eq("club_uuid", signedInUserId)
        .single();
      if (userProfileError) throw userProfileError;

      const signedInUserClubName = userProfileData.club_name;
      setSignedInUserClubName(signedInUserClubName);

      const { data: conversationData, error: conversationError } =
        await supabase
          .from("conversations")
          .select("*")
          .or(
            `participant1_id.eq.${signedInUserClubName},participant2_id.eq.${signedInUserClubName}`
          );
      if (conversationError) throw conversationError;

      const existingConversation = conversationData.find(
        (convo) =>
          (convo.participant1_id === signedInUserClubName &&
            convo.participant2_id === clubName) ||
          (convo.participant1_id === clubName &&
            convo.participant2_id === signedInUserClubName)
      );

      if (existingConversation) {
        const existingConversationId = existingConversation.conversation_id;
        const { data: messagesData, error: messagesError } = await supabase
          .from("messages")
          .select("*")
          .eq("conversation_id", existingConversationId);
        if (messagesError) throw messagesError;
        setMessages(messagesData);
        setExistingConversationId(existingConversationId);
      } else {
        const length = 4;
        const convoId = generateSecureRandomCode(length);

        const { data: newConvoData, error: newConvoError } = await supabase
          .from("conversations")
          .insert([
            {
              conversation_id: convoId,
              participant1_id: signedInUserClubName,
              participant2_id: clubName,
            },
          ]);
        if (newConvoError) throw newConvoError;
        console.log("New conversation created:", newConvoData);
        setExistingConversationId(convoId);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return {
    messages,
    clickedClubName,
    signedInUserClubName,
    existingConversationId,
    checkExistingConversation,
  };
};
