import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const OtpVerify = () => {
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const verifyOtp = () => {
    const { randomCode } = location.state || {};
    otp === randomCode.toString() ? signUserUp() : alert("OTP Incorrect");
  };

  const signUserUp = () => {
    navigate("/loginaftersigningup", {
      state: {
        email: location.state.email,
        password: location.state.password,
      },
    });
  };

  return (
    <div>
      <h1>OTP Verify</h1>
      <input
        className="input"
        type="text"
        placeholder="OTP"
        onChange={(e) => setOtp(e.target.value)}
      />
      <button onClick={verifyOtp} className="button">
        Verify
      </button>
    </div>
  );
};

export default OtpVerify;
