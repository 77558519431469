import React, { useState, useEffect } from "react";
import { supabase } from "../Components/supabaseClient";
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ClubList = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getClubList = async () => {
      const { data, error } = await supabase.from("userProfile").select("*");
      if (error) {
        console.log("Error fetching club list:", error);
      } else {
        setData(data);
      }
    };
    getClubList();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const viewProfile = (clubName) => {
    navigate("/clubProfile", {
      state: { clubName: clubName },
    });
  };

  const filteredClubs = data.filter((club) =>
    club.club_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container style={styles.container}>
      <Typography variant="h4" align="center" gutterBottom>
        All Clubs
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Search clubs..."
        fullWidth
        value={searchQuery}
        onChange={handleSearchChange}
        style={styles.searchField}
      />
      <List style={styles.list}>
        {filteredClubs.map((club) => (
          <ListItem
            key={club.id}
            button
            onClick={() => viewProfile(club.club_name)}
            style={styles.listItem}
          >
            <ListItemText primary={club.club_name} />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  searchField: {
    marginBottom: "20px",
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "background.paper",
  },
  listItem: {
    margin: "10px 0",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

export default ClubList;
