import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import subscribeToMessages from "./subscribeToMessages";

const useSendMessage = (existingConversationId, signedInUserClubName) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (existingConversationId) {
      const unsubscribe = subscribeToMessages(
        existingConversationId,
        setMessages
      );
      return () => {
        unsubscribe();
      };
    }
  }, [existingConversationId]);

  const sendMessage = async () => {
    try {
      const { error: messageError } = await supabase.from("messages").insert([
        {
          conversation_id: existingConversationId,
          sender_id: signedInUserClubName,
          message_content: message,
        },
      ]);
      if (messageError) throw messageError;
      setMessage("");
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return {
    message,
    setMessage,
    messages,
    sendMessage,
  };
};

export default useSendMessage;
