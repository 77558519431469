import React from "react";
import { supabase } from "../Components/supabaseClient";
import {
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";

const Addfixture = () => {
  const [club_name, setClubName] = React.useState("");
  const [fixtureLocation, setFixtureLocation] = React.useState("");
  const [fixture_date, setFixtureDate] = React.useState("");
  const [fixture_team_name, setFixtureTeamName] = React.useState("");
  const [fixture_email, setFixtureEmail] = React.useState("");
  const [fixture_description, setFixtureDescription] = React.useState("");

  React.useEffect(() => {
    const getClub = async () => {
      const { data, error } = await supabase
        .from("userProfile")
        .select("club_name")
        .eq("club_uuid", (await supabase.auth.getUser()).data.user.id);
      if (data) {
        setClubName(data[0].club_name);
        console.log("Club Name", club_name);
      }
      console.log("Club", data, error);
    };
    getClub();
    // eslint-disable-next-line
  }, []);

  const addFixture = async () => {
    const { data, error } = await supabase
      .from("fixture")
      .insert([
        {
          club_name: club_name,
          fixture_venue: fixtureLocation,
          fixture_date: new Date(fixture_date),
          fixture_team_name: fixture_team_name,
          fixture_email: fixture_email,
          fixture_description: fixture_description,
          club_uuid: (await supabase.auth.getUser()).data.user.id,
        },
      ])
      .select();
    window.location.href = "/dashboard";
    console.log("Fixture Added", data, error);
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Add Fixture
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <FormControl fullWidth margin="normal">
          <InputLabel>Location</InputLabel>
          <Select
            value={fixtureLocation}
            onChange={(e) => setFixtureLocation(e.target.value)}
          >
            <MenuItem value="">
              <em>Select location</em>
            </MenuItem>
            <MenuItem value="home">Home</MenuItem>
            <MenuItem value="away">Away</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Team Name"
          variant="outlined"
          value={fixture_team_name}
          onChange={(e) => setFixtureTeamName(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Contact Email"
          variant="outlined"
          type="email"
          value={fixture_email}
          onChange={(e) => setFixtureEmail(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Team Description"
          variant="outlined"
          multiline
          value={fixture_description}
          onChange={(e) => setFixtureDescription(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          type="date"
          label="Date"
          InputLabelProps={{ shrink: true }}
          value={fixture_date}
          onChange={(e) => setFixtureDate(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: "1rem" }}
          onClick={addFixture}
        >
          Add Fixture
        </Button>
      </Box>
    </Container>
  );
};

export default Addfixture;
