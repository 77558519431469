function generateSecureRandomCode(length) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
}

const code = generateSecureRandomCode(4);
// eslint-disable-next-line no-unused-vars
const convoId = generateSecureRandomCode(code);

export default generateSecureRandomCode;
