import React, { useState } from "react";
import { supabase } from "../Components/supabaseClient";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
} from "@mui/material";

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const loginUser = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    if (error) {
      alert("Error logging in: " + error.message);
    } else {
      window.location.href = "/dashboard";
    }
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: "5rem" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "1rem" }}
          onClick={loginUser}
        >
          Login
        </Button>
        <Typography variant="body2" style={{ marginTop: "1rem" }}>
          Don't have an account?{" "}
          <Link href="/signup" underline="hover">
            Sign Up
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
